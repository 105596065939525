<template>
  <iframe
    v-resize="{ log: false }"
    width="100%"
    src="https://internal.koval-distillery.com"
    frameborder="0"
  />
</template>

<script>
import Vue from "vue";
import iFrameResize from "iframe-resizer/js/iframeResizer";

Vue.directive("resize", {
  bind: function(el, { value = {} }) {
    el.addEventListener("load", () => {
      iFrameResize(value, el);
    });
  }
});

export default {
  name: "KnowledgeBase",
  components: {},
  data: function() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style scoped>
/*
.vue-frame-wrapper {
    margin-right: -10px;
}

.vue-frame {
    height: calc(100vh);
    border: 0px solid lightgrey;
    margin: -10px;

}
*/
</style>
