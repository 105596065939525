var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('iframe', {
    directives: [{
      name: "resize",
      rawName: "v-resize",
      value: {
        log: false
      },
      expression: "{ log: false }"
    }],
    attrs: {
      "width": "100%",
      "src": "https://internal.koval-distillery.com",
      "frameborder": "0"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }